import { DictionaryItem } from 'types/IDictionary'

/** Виды дозаказов */
export enum RESEARCH_REQUEST_TYPE {
  /** Пересканирование */
  RESCAN = 'RESCAN',
  /** Перерезка */
  NEW_CUTS = 'NEW_CUTS',
  /** Последовательные срезы */
  NEW_SERIAL_CUTS = 'NEW_SERIAL_CUTS',
  /** Новая окраска (общий тип) */
  NEW_STAIN = 'NEW_STAIN',
  /** Гистохимическая окраска */
  NEW_STAIN_HC = 'NEW_STAIN_HC',
  /** ИГХ-окраска (в этот случай) */
  NEW_STAIN_IHC = 'NEW_STAIN_IHC',
  /** ИГХ-окраска (новым случаем) */
  NEW_STAIN_IHC_NEW_CASE = 'NEW_STAIN_IHC_NEW_CASE',
}

/** Виды окрасок */
export enum STAIN_TYPE {
  /** Гистохимическая окраска */
  HC = 'HC',
  /** ИГХ-окраска */
  IHC = 'IHC',
}

/** Виды статусов по дозаказам */
export enum STATUS_ADDITIONAL_ORDER {
  CREATION_PENDING = 'CREATION_PENDING',
  CREATED = 'CREATED',
  IN_PROGRESS = 'IN_PROGRESS',
  READY = 'READY',
  CANCELLED = 'CANCELLED',
  FAILED = 'FAILED',
}

export interface ISlideAdditional {
  caseBlockReferenceId?: number
  caseSlideReferenceId?: number
  stain?: DictionaryItem
  blockExternalId?: string
}

export type NewSerialCutsPayload = {
  newCaseRequested: boolean
  preparationComment?: string
  slides: ISlideAdditional[]
  type: RESEARCH_REQUEST_TYPE
}
