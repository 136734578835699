import { useTypedSelector } from 'app/redux/lib/selector'
import { viewerPageSlice } from 'pages/viewer'
import React, { FC, ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useQueryParams } from 'shared/lib/hooks'
import { useSettingsAndUserRoles } from 'shared/lib/workspaces'
import { CheckboxElement, TooltipElement } from 'shared/ui/kit'
import styled from 'styled-components/macro'
import ISlide, { SlideDefect } from 'types/ISlide'
import TViewerId from 'types/TViewerId'
import VIEWER_COLORS from 'viewer/container/ui/VIEWER_COLORS'

import { SlideMarkers } from './SlideMarkers'

const ThumbnailContent = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
`

const InnerContent = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: hidden;
`

const StyledThumbnail = styled.div<{ activeCursor?: TViewerId; selectedByViewer?: TViewerId }>`
  position: relative;
  background-color: ${({ selectedByViewer }) => selectedByViewer && VIEWER_COLORS[selectedByViewer]};
  cursor: pointer;
  width: 100%;
  border-radius: 1px;
  margin-bottom: 10px;
  box-shadow: ${({ selectedByViewer }) =>
    selectedByViewer ? `0 0 0 4px ${VIEWER_COLORS[selectedByViewer]}` : '0 0 0 2px var(--color-border-1)'};
  &:hover {
    box-shadow: ${({ selectedByViewer }) => !selectedByViewer && '0 0 0 2px var(--color-purple)'};
  }
`

const AdaptiveImg = styled.div<{ type?: string; isShowLabel?: boolean }>`
  position: relative;
  min-height: 80px;

  &:after {
    min-height: 80px;
    content: '';
    display: block;
    padding-bottom: ${({ isShowLabel, type }) => (type !== 'MACRO' && isShowLabel ? '50%' : '100%')};
    position: relative;
  }
`
const InnerImg = styled.div<{ type?: string }>`
  display: flex;
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 1.5px;
  background-color: var(--color-white);
`

const CheckboxElementStyle = styled(CheckboxElement)<{ disabled?: boolean }>`
  position: absolute;
  padding: 5px;
  top: 0;
  left: 0;
  z-index: 2;
  line-height: 12px;
  background: rgba(255, 255, 255, 0.72);
  backdrop-filter: blur(70px) saturate(5);
  border-radius: 3px;

  span {
    opacity: ${({ disabled }) => (disabled ? '0.5' : '1')};
  }
`

const Wrapper = styled.div`
  position: relative;
  width: 100%;
`

type Props = {
  /** данные слайда */
  slide?: ISlide
  /** идентификатор слайда */
  slideId?: number
  /** контент подвала */
  footer?: ReactNode
  /** основной контент слайда */
  content?: ReactNode
  /** идентификатор активного просматривающего */
  activeCursor: TViewerId
  /** флаг отслеживающий видимость этикетки и превью */
  shouldDisplayLabelAndPreview?: boolean
  /** идентификатор просматривающего, выбравшего слайд */
  selectedByViewer?: TViewerId
  /** Обработчик события клика для слайда */
  onClick?: React.MouseEventHandler<HTMLDivElement>
  /** Обработчик открытия контекстного меню */
  handleContextMenu?: (e: React.MouseEvent<HTMLDivElement>) => void
  /** Массив текущих слайдов */
  slides?: ISlide[]
}

const AdaptiveThumbnail: FC<Props> = ({
  activeCursor,
  children,
  content,
  footer,
  handleContextMenu,
  onClick,
  selectedByViewer,
  shouldDisplayLabelAndPreview,
  slide,
  slides,
}) => {
  const [hasChecked, setHasChecked] = useState(false)
  const [currentEtarget, setCurrentEtarget] = useState<any>()
  const { caseReferencesBlocks, caseReferencesSlides, selectedSlides } = useTypedSelector((state) => state.viewerPage)
  const { isResearchRequestEnabled } = useSettingsAndUserRoles()
  const dispatch = useDispatch()
  const queryParams = useQueryParams()
  const { t } = useTranslation()
  const slideGroupType = slide?.groupType
  const scrollRef = useRef<HTMLDivElement>(null)
  const slideIdFromUrl = Number(queryParams.get('slideId'))
  const caseSlideReference = caseReferencesSlides?.find((refSlide) => refSlide?.slideExternalId === slide?.barcode)

  const selectedSlide = selectedSlides.find((item) => item.slideId === slide?.slideId)

  const isCheckbox = selectedSlide?.isCheckboxVisible
  const isChecked = selectedSlide?.isChecked
  const isDisabled = caseSlideReference?.caseSlideReferenceId

  const caseBlockReferenceId = caseReferencesBlocks?.find(
    (refBlock) => refBlock?.blockExternalId === caseSlideReference?.blockExternalId,
  )?.caseBlockReferenceId

  const onMouseEnter = useCallback(() => {
    if (isResearchRequestEnabled) {
      dispatch(
        viewerPageSlice.actions.setSelectedSlidesState({
          blockReferenceId: Number(caseBlockReferenceId),
          isCheckboxVisible: true,
          slideId: slide?.slideId,
          slideRererenceId: Number(caseSlideReference?.caseSlideReferenceId),
        }),
      )
    }
  }, [dispatch, isResearchRequestEnabled, caseSlideReference, caseBlockReferenceId])

  const onMouseLeave = useCallback(() => {
    const someSlideCheckboxVisible = selectedSlides.some((item) => item.isChecked)
    const slideCheckboxVisible = selectedSlides.find((item) => item.slideId === slide?.slideId)?.isChecked

    if (!slideCheckboxVisible && !someSlideCheckboxVisible) {
      dispatch(
        viewerPageSlice.actions.setSelectedSlidesState({
          blockReferenceId: Number(caseBlockReferenceId),
          isCheckboxVisible: false,
          slideId: slide?.slideId,
          slideRererenceId: Number(caseSlideReference?.caseSlideReferenceId),
        }),
      )
    }
  }, [dispatch, caseBlockReferenceId, caseSlideReference, selectedSlides])

  const onChangeCheckbox = useCallback(
    (e) => {
      e.preventDefault()
      dispatch(
        viewerPageSlice.actions.setSelectedSlidesState({
          blockReferenceId: Number(caseBlockReferenceId),
          isChecked: !isChecked,
          slideId: slide?.slideId,
          slideRererenceId: Number(caseSlideReference?.caseSlideReferenceId),
        }),
      )
    },
    [dispatch, caseBlockReferenceId, caseSlideReference, slide, isChecked],
  )

  const handleClick = useCallback(
    (e) => {
      if (slide?.thumbnails.medium !== 'FAILED_SLIDE') {
        onClick?.(e)
      }
    },
    [slide, onClick],
  )

  const isNewSlideIconVisible = useMemo(
    () => slide?.viewed === false && slide?.groupType === 'MICRO' && slide?.state === 'AVAILABLE',
    [slide?.viewed, slide?.groupType, slide?.state],
  )

  const isDefectSlide = useMemo(
    () => slide?.slideDefect === SlideDefect.DEFECT && slide?.groupType === 'MICRO',
    [slide?.slideDefect, slide?.groupType],
  )

  const isDefectFalsePositive = useMemo(
    () => slide?.slideDefect === SlideDefect.FALSE_POSITIVE && slide?.groupType === 'MICRO',
    [slide?.slideDefect, slide?.groupType],
  )

  const isMarkers = isDefectFalsePositive || isDefectSlide || isNewSlideIconVisible

  useEffect(() => {
    if (slideIdFromUrl === slide?.slideId) {
      setTimeout(() => {
        scrollRef.current && scrollRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' })
      }, 0)
    }
  }, [slideIdFromUrl])

  useEffect(() => {
    const slideCheckboxVisible = selectedSlides.find((item) => item.slideId === slide?.slideId)?.isChecked

    if (currentEtarget && slideCheckboxVisible) {
      handleContextMenu?.(currentEtarget)
    }
  }, [currentEtarget])

  useEffect(() => {
    const isSomeCheked = selectedSlides.some((item) => item.isChecked)

    if (isSomeCheked && !hasChecked) {
      setHasChecked(true)
      slides?.forEach((slide) => {
        const caseSlideReference = caseReferencesSlides?.find(
          (refSlide) => refSlide?.slideExternalId === slide?.barcode,
        )

        const caseBlockReferenceId = caseReferencesBlocks?.find(
          (refBlock) => refBlock?.blockExternalId === caseSlideReference?.blockExternalId,
        )?.caseBlockReferenceId

        dispatch(
          viewerPageSlice.actions.setSelectedSlidesState({
            blockReferenceId: Number(caseBlockReferenceId),
            isCheckboxVisible: true,
            slideId: slide.slideId,
            slideRererenceId: Number(caseSlideReference?.caseSlideReferenceId),
          }),
        )
      })
    }
  }, [selectedSlides])

  return (
    <TooltipElement placement={'right'} title={slide?.thumbnails.medium === 'FAILED_SLIDE' && t('Слайд не загружен')}>
      <Wrapper onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
        {isResearchRequestEnabled && slide?.groupType !== 'MACRO' && isCheckbox && (
          <TooltipElement title={!isDisabled ? t('Нет доступных действий для слайда') : ''}>
            <CheckboxElementStyle disabled={!isDisabled} checked={isChecked} onClick={(e) => onChangeCheckbox(e)} />
          </TooltipElement>
        )}
        <StyledThumbnail
          activeCursor={activeCursor}
          selectedByViewer={selectedByViewer}
          onClick={handleClick}
          ref={scrollRef}
          onContextMenu={handleContextMenu}
        >
          {isMarkers && (
            <SlideMarkers
              isNewSlideIconVisible={isNewSlideIconVisible}
              isDefectSlide={isDefectSlide}
              isDefectFalsePositive={isDefectFalsePositive}
            />
          )}
          <ThumbnailContent>
            <InnerContent>
              <AdaptiveImg
                data-testid={'preview-left-panel'}
                isShowLabel={shouldDisplayLabelAndPreview}
                type={slideGroupType}
              >
                {content}
                <InnerImg type={slideGroupType}>{children}</InnerImg>
              </AdaptiveImg>
              {footer}
            </InnerContent>
          </ThumbnailContent>
        </StyledThumbnail>
      </Wrapper>
    </TooltipElement>
  )
}

export default AdaptiveThumbnail
