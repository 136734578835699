import { Card } from 'antd'
import { RESEARCH_REQUEST_TYPE, STAIN_TYPE } from 'features/additional-order/api/type'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { EmptyElement, SmallIconButtonElement, TextElement } from 'shared/ui/kit'
import styled from 'styled-components'

import { BlockCardProps } from './types'

export const BlockCard: React.FC<BlockCardProps> = ({ block, isSelected, onClick, onRemove, requestType }) => {
  const { t } = useTranslation()
  const { id, stains } = block
  const handleBlockClick = () => {
    onClick(id)
  }

  /** Фильтруем отображение окрасок для типа дозаказа */
  const visibleStains = stains.filter(
    (stain) =>
      stain.stainType === (RESEARCH_REQUEST_TYPE.NEW_STAIN_HC === requestType ? STAIN_TYPE.HC : STAIN_TYPE.IHC),
  )

  return (
    <StyledCard
      title={block.name}
      isSelected={!!isSelected}
      isEmpty={!!stains.length}
      onClick={handleBlockClick}
      extra={
        <SmallIconButtonElement icon="delete" title={t('Удалить')} placement="left" onClick={() => onRemove(id)} />
      }
    >
      {!visibleStains.length && (
        <div>
          <EmptyElement description={t('Выберите окраски для дозаказа')} />
        </div>
      )}
      {visibleStains.map(({ id, name }) => (
        <StainTextElement ellipsis key={id}>
          {name}
        </StainTextElement>
      ))}
    </StyledCard>
  )
}

const StyledCard = styled(Card)<{ isSelected: boolean; isEmpty: boolean }>`
  margin-top: 8px;
  width: 234px;
  cursor: pointer;
  :hover {
    box-shadow: 0 0 0 1px var(--color-border-1) inset;
    .ant-card-head {
      background: var(--color-bg-3);
      .ant-card-head-wrapper .ant-card-extra {
        display: block;
      }
    }
  }

  .ant-card-head {
    background: ${({ isSelected }) => (isSelected ? 'var(--color-bg-3)' : 'none')};
    height: 24px;
    min-height: 24px;
    padding: 0 8px;
    font-size: 12px;
    line-height: 16px;

    .ant-card-head-wrapper {
      height: 24px;
      .ant-card-extra {
        display: none;
      }
    }
  }
  .ant-card-body {
    padding: 0 16px;
    gap: 10px;
    display: flex;
    flex-direction: column;
    .ant-empty-normal {
      margin: 8px 0;
    }
  }
  &.ant-card {
    background: ${({ isSelected }) => (isSelected ? 'var(--color-bg-3)' : 'none')};
  }
`
const StainTextElement = styled(TextElement)`
  &.ant-typography {
    font-size: 13px;
    line-height: 18px;
  }
`
